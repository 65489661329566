import { fetchData } from '../../utils/apiUtils';
import { API_URL } from '../constants';

export const fetchRefresh = async () => {
	try {
		const res = await fetchData(`${API_URL}/refresh`, 'GET', null, true);
		if (res.status === 200) {
			const jsonRes = await res.json();
			return jsonRes.accessToken;
		} else {
			return false;
		}
	} catch (err) {
		console.error(err);
		return false;
	}
};

export const fetchLogout = async () => {
	try {
		const res = await fetchData(`${API_URL}/logout`, 'POST', null, true);
		if (res.status === 200) {
			return true;
		} else {
			return false;
		}
	} catch (err) {
		console.error(err);
		return false;
	}
};

export const fetchLogin = async body => {
	try {
		const res = await fetchData(`${API_URL}/login`, 'POST', body, true);
		if (res.status === 200) {
			const jsonRes = await res.json();
			return jsonRes;
		} else {
			return null;
		}
	} catch (err) {
		console.error(err);
		return null;
	}
};

export const fetchPrivate = async token => {
	try {
		const res = await fetchData(`${API_URL}/private`, 'GET', null, true, token);
		if (res.status === 200) {
			const jsonRes = await res.json();
			return true;
		} else {
			return false;
		}
	} catch (err) {
		console.error(err);
		return false;
	}
};

export const fetchSignup = async body => {
	try {
		const res = await fetchData(`${API_URL}/signup/invite`, 'POST', body, true);
		if (res.status === 200) {
			const jsonRes = await res.json();
			return jsonRes;
		} else {
			return null;
		}
	} catch (err) {
		console.error(err);
		return null;
	}
};
