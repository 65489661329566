import { fetchData } from '@utils/apiUtils/apiUtils';
import { API_URL } from '../constants';

// export const fetchRefresh = async () => {
// 	try {
// 		console.log('fetch: ', fetch);
// 		const res = await fetchData(`${API_URL}/refresh`, 'GET', null, true);
// 		if (res.status === 200) {
// 			const jsonRes = await res.json();
// 			return jsonRes.accessToken;
// 		} else {
// 			return false;
// 		}
// 	} catch (err) {
// 		console.error(err);
// 		return false;
// 	}
// };

// export const fetchLogout = async () => {
// 	try {
// 		const res = await fetchData(`${API_URL}/logout`, 'POST', null, true);
// 		if (res.status === 200) {
// 			return true;
// 		} else {
// 			return false;
// 		}
// 	} catch (err) {
// 		console.error(err);
// 		return false;
// 	}
// };

// export const fetchLogin = async body => {
// 	try {
// 		const res = await fetchData(`${API_URL}/login`, 'POST', body, true);
// 		if (res.status === 200) {
// 			const jsonRes = await res.json();
// 			return jsonRes;
// 		} else {
// 			return null;
// 		}
// 	} catch (err) {
// 		console.error(err);
// 		return null;
// 	}
// };

// export const fetchPrivate = async token => {
// 	try {
// 		const res = await fetchData(`${API_URL}/private`, 'GET', null, true, token);
// 		if (res.status === 200) {
// 			const jsonRes = await res.json();
// 			return true;
// 		} else {
// 			return false;
// 		}
// 	} catch (err) {
// 		console.error(err);
// 		return false;
// 	}
// };

// export const fetchSignup = async body => {
// 	try {
// 		const res = await fetchData(`${API_URL}/signup/invite`, 'POST', body, true);
// 		if (res.status === 200) {
// 			const jsonRes = await res.json();
// 			return jsonRes;
// 		} else {
// 			return null;
// 		}
// 	} catch (err) {
// 		console.error(err);
// 		return null;
// 	}
// };

// auth.js

/**
 * Helper function to handle fetch responses.
 * @param {Response} response - The fetch response object.
 * @returns {Object} - The parsed JSON data.
 * @throws Will throw an error if the response is not ok.
 */
const handleResponse = async response => {
	const contentType = response.headers.get('content-type');
	let data = null;

	if (contentType && contentType.includes('application/json')) {
		data = await response.json();
	}

	if (!response.ok) {
		const error = (data && data.message) || response.statusText;
		throw new Error(error);
	}

	return data;
};

/**
 * Refreshes the access token using the refresh token stored in cookies.
 * @returns {string|false} - The new access token if successful, else false.
 */
export const fetchRefresh = async () => {
	try {
		const res = await fetch(`${API_URL}/refresh`, {
			method: 'GET',
			credentials: 'include', // Include cookies
			headers: {
				'Content-Type': 'application/json',
			},
		});

		if (res.status === 200) {
			const jsonRes = await handleResponse(res);
			return jsonRes.accessToken;
		} else {
			return false;
		}
	} catch (err) {
		console.error('Error in fetchRefresh:', err);
		return false;
	}
};

/**
 * Logs out the user by invalidating the refresh token.
 * @returns {boolean} - True if logout was successful, else false.
 */
export const fetchLogout = async () => {
	try {
		const res = await fetch(`${API_URL}/logout`, {
			method: 'POST',
			credentials: 'include', // Include cookies
			headers: {
				'Content-Type': 'application/json',
			},
		});

		if (res.status === 200) {
			return true;
		} else {
			return false;
		}
	} catch (err) {
		console.error('Error in fetchLogout:', err);
		return false;
	}
};

/**
 * Logs in the user with email and password.
 * Handles 2FA setup or verification based on the user's role.
 * @param {Object} body - The login payload containing email and password.
 * @returns {Object|null} - The response data from the server or null if failed.
 */
export const fetchLogin = async body => {
	try {
		const res = await fetch(`${API_URL}/login`, {
			method: 'POST',
			credentials: 'include', // Include cookies
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		});

		if (res.status === 200) {
			const jsonRes = await handleResponse(res);
			return jsonRes;
		} else {
			return null;
		}
	} catch (err) {
		console.error('Error in fetchLogin:', err);
		return null;
	}
};

/**
 * Accesses a private route that requires authentication.
 * @param {string} token - The access token for authentication.
 * @returns {boolean} - True if access is granted, else false.
 */
export const fetchPrivate = async token => {
	try {
		const res = await fetch(`${API_URL}/private`, {
			method: 'GET',
			credentials: 'include', // Include cookies
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		});

		if (res.status === 200) {
			await handleResponse(res);
			return true;
		} else {
			return false;
		}
	} catch (err) {
		console.error('Error in fetchPrivate:', err);
		return false;
	}
};

/**
 * Signs up the user via an invitation.
 * @param {Object} body - The signup payload containing email, password, and activationKey.
 * @returns {Object|null} - The response data from the server or null if failed.
 */
export const fetchSignup = async body => {
	try {
		const res = await fetch(`${API_URL}/signup/invite`, {
			method: 'POST',
			credentials: 'include', // Include cookies
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		});

		if (res.status === 200) {
			const jsonRes = await handleResponse(res);
			return jsonRes;
		} else {
			return null;
		}
	} catch (err) {
		console.error('Error in fetchSignup:', err);
		return null;
	}
};

/**
 * Sets up Two-Factor Authentication by verifying the TOTP code.
 * @param {Object} body - The setup payload containing userId, token, and TOTP code.
 * @returns {Object|null} - The response data from the server or null if failed.
 */
export const fetchSetup2FA = async body => {
	try {
		const res = await fetch(`${API_URL}/setup-2fa`, {
			method: 'POST',
			credentials: 'include', // Include cookies
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${body.tempToken}`, // Temporary token for 2FA setup
			},
			body: JSON.stringify({
				userId: body.userId,
				token: body.token, // TOTP code
			}),
		});

		if (res.status === 200) {
			const jsonRes = await handleResponse(res);
			return jsonRes;
		} else {
			return null;
		}
	} catch (err) {
		console.error('Error in fetchSetup2FA:', err);
		return null;
	}
};

/**
 * Verifies the Two-Factor Authentication TOTP code during login.
 * @param {Object} body - The verification payload containing userId, tempToken, and TOTP code.
 * @returns {Object|null} - The response data from the server or null if failed.
 */
export const fetchVerify2FA = async body => {
	try {
		const res = await fetch(`${API_URL}/verify-2fa`, {
			method: 'POST',
			credentials: 'include', // Include cookies
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${body.tempToken}`, // Temporary token for 2FA verification
			},
			body: JSON.stringify({
				userId: body.userId,
				token: body.token, // TOTP code
			}),
		});

		if (res.status === 200) {
			const jsonRes = await handleResponse(res);
			return jsonRes;
		} else {
			return null;
		}
	} catch (err) {
		console.error('Error in fetchVerify2FA:', err);
		return null;
	}
};
