import React, { useState, Suspense, useCallback } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const AuthScreen = React.lazy(() =>
	import('@components/pages/AuthorizationScreens/AuthScreen')
);
const HomeScreen = React.lazy(() =>
	import('./src/components/pages/MainScreens/HomeScreen')
);
const PurchaseScreen = React.lazy(() =>
	import('@components/pages/MainScreens/PurchaseScreen')
);
const LocationScreen = React.lazy(() =>
	import('@components/pages/MainScreens/LocationScreen')
);
const AnalyticsScreen = React.lazy(() =>
	import('@components/pages/AdminScreens/AnalyticsScreen')
);
const RosterScreen = React.lazy(() =>
	import('@components/pages/AdminScreens/RosterScreen')
);
const InventoryScreen = React.lazy(() =>
	import('@components/pages/AdminScreens/InventoryScreen')
);
const ReportsPage = React.lazy(() =>
	import('@components/pages/AdminScreens/ReportsPage')
);
const MapScreen = React.lazy(() =>
	import('@components/pages/MainScreens/MapScreen')
);
const TestPage = React.lazy(() =>
	import('./src/components/pages/TestScreen/TestScreen')
);
const SignupScreen = React.lazy(() =>
	import('./src/components/pages/AuthorizationScreens/SignupScreen')
);
const NoticesScreen = React.lazy(() =>
	import('@components/pages/AdminScreens/NoticesScreen')
);
const IcePurchaseScreen = React.lazy(() =>
	import('@components/pages/MainScreens/IcePurchaseScreen')
);
const SchedulingPage = React.lazy(() =>
	import('./src/components/pages/AdminScreens/SchedulingPage')
);

import PersistLogin from '@services/PersistLogin/PersistLogin';
import RequireAuth from '@services/RequireAuth/RequireAuth';
import { AuthProvider } from '@context/AuthProvider/AuthProvider';
import { ScheduleProvider } from './src/context/ScheduleProvider';
import { WebNavigationProvider } from '@context/WebNavigationProvider/WebNavigationProvider';
import { BannerProvider } from '@context/BannerProvider/BannerProvider';
import ProtectedRoute from '@services/ProtectedRoute/ProtectedRoute';
import LoadingPage from './src/components/pages/LoadingPage';
import FetchInterceptorProvider from '@context/FetchInterceptorsProvider/FetchInterceptorProvider';

const STRIPE_PUBLISHABLE_KEY =
	'pk_test_51LMbyWBbVllh4lyvvO2cte2uwCBJ2Ca0cxqFqPCqYdZD8DeHI5ZDiDRBNgWAv651ByyFeqjbt2kb2Bsx35Dpf1g400ohHqP2AG';
const stripeTestPromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

export default function App() {
	return (
		<HashRouter>
			<AuthProvider>
				<FetchInterceptorProvider>
					<WebNavigationProvider>
						<BannerProvider>
							<Routes>
								<Route path="/" element={<ProtectedRoute />}>
									{/* public routes */}
									<Route
										path="/"
										element={
											<Suspense fallback={<LoadingPage />}>
												<AuthScreen />
											</Suspense>
										}
									/>
									<Route
										path="/signup"
										element={
											<Suspense fallback={<LoadingPage />}>
												<SignupScreen />
											</Suspense>
										}
									/>
									{/* <Route path="/unauthorized" element={<TestPage />} exact /> */}

									{/* private routes */}
									<Route element={<PersistLogin />}>
										{/* all users */}
										<Route
											element={
												<RequireAuth
													allowedRoles={['Admin', 'Ice', 'Boxes', 'Manager', 'Finance']}
												/>
											}
										>
											<Route
												path="/home"
												element={
													<Suspense fallback={<LoadingPage />}>
														<HomeScreen />
													</Suspense>
												}
												exact
											/>
										</Route>

										<Route
											element={<RequireAuth allowedRoles={['Admin', 'Ice', 'Manager']} />}
										>
											<Route
												path="/new_sale"
												element={
													<Suspense fallback={<LoadingPage />}>
														<Elements stripe={stripeTestPromise}>
															<IcePurchaseScreen />
														</Elements>
													</Suspense>
												}
												exact
											/>
										</Route>

										<Route
											element={
												<RequireAuth allowedRoles={['Admin', 'Boxes', 'Manager']} />
											}
										>
											<Route
												path="/bookings"
												element={
													<Suspense fallback={<LoadingPage />}>
														<LocationScreen />
													</Suspense>
												}
												exact
											/>
											<Route
												path="/map"
												element={
													<Suspense fallback={<LoadingPage />}>
														<MapScreen />
													</Suspense>
												}
												exact
											/>
											<Route
												path="/new_booking"
												element={
													<Suspense fallback={<LoadingPage />}>
														<Elements stripe={stripeTestPromise}>
															<PurchaseScreen />
														</Elements>
													</Suspense>
												}
												exact
											/>
										</Route>

										{/* admin/manager pages */}
										<Route
											element={
												<RequireAuth allowedRoles={['Admin', 'Manager', 'Finance']} />
											}
										>
											<Route
												path="/scheduling"
												element={
													<Suspense fallback={<LoadingPage />}>
														<ScheduleProvider>
															<SchedulingPage />
														</ScheduleProvider>
													</Suspense>
												}
												exact
											/>
											<Route
												path="/sales"
												element={
													<Suspense fallback={<LoadingPage />}>
														<AnalyticsScreen />
													</Suspense>
												}
												exact
											/>
											<Route
												path="/roster"
												element={
													<Suspense fallback={<LoadingPage />}>
														<RosterScreen />
													</Suspense>
												}
												exact
											/>
											<Route
												path="/inventory"
												element={
													<Suspense fallback={<LoadingPage />}>
														<InventoryScreen />
													</Suspense>
												}
												exact
											/>
											<Route
												path="/notices"
												element={
													<Suspense fallback={<LoadingPage />}>
														<NoticesScreen />
													</Suspense>
												}
											/>
											<Route
												path="/reports"
												element={
													<Suspense fallback={<LoadingPage />}>
														<ReportsPage />
													</Suspense>
												}
											/>
											<Route path="/testing" element={<TestPage />} exact />
										</Route>
									</Route>
								</Route>
							</Routes>
						</BannerProvider>
					</WebNavigationProvider>
				</FetchInterceptorProvider>
			</AuthProvider>
		</HashRouter>
	);
}
