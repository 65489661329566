// packages/context/NavigationContext.js
import React, { createContext, useContext } from 'react';

const NavigationContext = createContext({
	navigate: route => {},
});

export const useAppNavigation = () => useContext(NavigationContext);

export default NavigationContext;
