import React from 'react';
import { View, Text, StyleSheet, Image } from 'react-native';
import LoadingText from '../atoms/LoadingText';
import SpinningIcon from '../atoms/SpinningIcon';

const LoadingPage = () => {
	return (
		<View style={styles.container}>
			{/* <LoadingText /> */}
			<SpinningIcon
				containerStyle={styles.loadingIconContainerStyle}
				iconStyle={styles.loadingIconStyle}
			/>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		justifyContent: 'center',
	},
	loadingIconContainerStyle: {
		marginTop: 50,
		justifyContent: 'center',
		alignItems: 'center',
		// flex: '',
	},
	loadingIconStyle: {
		width: 90,
		height: 90,
	},
	image: {
		marginTop: 50,
		width: 150,
		height: 150,
		resizeMode: 'contain',
	},
});

export default LoadingPage;
