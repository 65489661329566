import { fetchData } from '../../utils/apiUtils';
import { API_URL } from '../constants';

export const fetchAllInventoryLocations = async () => {
	try {
		const res = await fetchData(`${API_URL}/inventory/locations`, 'GET');
		if (res.status === 204) {
			return [];
		} else if (res.status === 200) {
			const jsonRes = await res.json();
			console.log(jsonRes);
			return jsonRes.locations;
		} else {
			return [];
		}
	} catch (err) {
		console.log(err);
		return [];
	}
};

export const fetchAllInventory = async () => {
	try {
		const res = await fetchData(`${API_URL}/inventory`, 'GET');
		console.log(res);
		if (res.status === 204) {
			return [];
		} else if (res.status === 200) {
			const jsonRes = await res.json();
			return jsonRes.inventory;
		} else {
			return [];
		}
	} catch (err) {
		console.log(err);
		return [];
	}
};

export const fetchMapLocations = async () => {
	try {
		const res = await fetchData(`${API_URL}/inventory?location=true`, 'GET');
		if (res.status !== 200) {
			return [];
		}
		const jsonRes = await res.json();
		return jsonRes.inventory;
	} catch (err) {
		console.error('Error fetching map locations:', err);
		return [];
	}
};

export const fetchInventoryId = async inventory_id => {
	try {
		const res = await fetchData(`${API_URL}/inventory/${inventory_id}`, 'GET');
		if (res.status !== 200) {
			console.error('Failed to fetch inventory:', res.statusText);
			// Return zero counts for both active and pending if the API call fails
			return {
				active: { umbrellas: 0, chairs: 0 },
				pending: { umbrellas: 0, chairs: 0 },
			};
		}

		const jsonRes = await res.json();
		let activeInventory = { umbrellas: 0, chairs: 0 };
		let pendingInventory = { umbrellas: 0, chairs: 0 };

		jsonRes.inventory.forEach(item => {
			if (item.status === 'active') {
				if (item.productName === 'umbrella') {
					activeInventory.umbrellas += item.quantity;
				} else if (item.productName === 'chair') {
					activeInventory.chairs += item.quantity;
				}
			} else if (item.status === 'pending') {
				if (item.productName === 'umbrella') {
					pendingInventory.umbrellas += item.quantity;
				} else if (item.productName === 'chair') {
					pendingInventory.chairs += item.quantity;
				}
			}
		});

		return {
			active: {
				umbrellas: activeInventory.umbrellas,
				chairs: activeInventory.chairs,
			},
			pending: {
				umbrellas: pendingInventory.umbrellas,
				chairs: pendingInventory.chairs,
			},
		};
	} catch (err) {
		console.error('Error fetching inventory:', err);
		// Return zero counts for both active and pending in case of an error
		return {
			active: { umbrellas: 0, chairs: 0 },
			pending: { umbrellas: 0, chairs: 0 },
		};
	}
};

export const putInventory = async (inventory_id, body) => {
	try {
		const res = await fetchData(
			`${API_URL}/inventory/${inventory_id}`,
			'PUT',
			body
		);
		if (res.status !== 200) {
			console.error('Failed to update inventory:', res.statusText);
			return false;
		}

		return true;
	} catch (err) {
		console.error('Error updating inventory:', err);
		return false;
	}
};

export const overbookInventory = async (inventory_id, body) => {
	try {
		const res = await fetchData(
			`${API_URL}/overbook/${inventory_id}`,
			'POST',
			body
		);
		if (res.status !== 200) {
			console.error('Failed to overbook inventory:', res.statusText);
			return false;
		}
		return true;
	} catch (err) {
		console.error('Error overbooking inventory:', err);
		return false;
	}
};

export const revertOverbookInventory = async inventory_id => {
	try {
		const res = await fetchData(
			`${API_URL}/revert-overbook/${inventory_id}`,
			'POST'
		);
		if (res.status !== 200) {
			console.error('Failed to revert overbook inventory:', res.statusText);
			return false;
		}
		return true;
	} catch (err) {
		console.error('Error reverting overbook inventory:', err);
		return false;
	}
};

export const rejectInventory = async inventory_id => {
	try {
		const res = await fetchData(
			`${API_URL}/inventory/reject/${inventory_id}`,
			'DELETE'
		);
		console.log(res);
		if (res.status !== 200) {
			console.error('Failed to reject inventory:', res.statusText);
			return false;
		}
		return true;
	} catch (err) {
		console.error('Error rejecting inventory:', err);
		return false;
	}
};

export const fetchTodaysInventory = async inventory_id => {
	try {
		console.log(inventory_id);
		const res = await fetchData(
			`${API_URL}/inventory/today/${inventory_id}`,
			'GET'
		);
		console.log(res);
		if (res.status !== 200) {
			console.error("Failed to fetch today's inventory:", res.statusText);
			return false;
		}
		const jsonRes = await res.json();
		return jsonRes;
	} catch (err) {
		console.error("Error fetching today's inventory:", err);
		return false;
	}
};

export const fetchInventoryCount = async query => {
	try {
		const res = await fetchData(`${API_URL}/inventory/count?${query}`, 'GET');
		if (res.status === 200) {
			const jsonRes = await res.json();
			return jsonRes.totalInventory;
		} else {
			return 0;
		}
	} catch (err) {
		console.log(err);
		return 0;
	}
};

export const fetchInventoryData = async (offset, query) => {
	try {
		const res = await fetchData(
			`${API_URL}/inventory/bulk?offset=${offset}&${query}`,
			'GET'
		);
		if (res.status === 200) {
			const jsonRes = await res.json();
			return jsonRes.inventory;
		} else {
			return [];
		}
	} catch (err) {
		console.log(err);
		return [];
	}
};
