import { useState, useEffect, useCallback } from 'react';
import setupFetchInterceptors from './setupFetchInterceptors';

/**
 * A custom hook for fetching data with both automatic and manual trigger options.
 * @param {Function} apiFunc The API function to call for fetching data.
 * @param {Array} deps Dependencies for which changes will cause the data to re-fetch.
 * @param {boolean} fetchOnMount Determines whether to fetch data automatically on mount.
 * @returns The fetched data, loading state, error, and a function to manually trigger a re-fetch.
 */
const useFetch = (apiFunc, dependencies = [], fetchOnMount = true) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [params, setParams] = useState([]);

  setupFetchInterceptors();

  // Update to accept dynamic parameters
  const fetchData = useCallback(
    async (dynamicParams = []) => {
      setIsLoading(true);
      setError(null);
      try {
        const result = await apiFunc(...dynamicParams);
        setData(result);
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    },
    [apiFunc, ...dependencies]
  ); // Include dependencies

  useEffect(() => {
    if (fetchOnMount) {
      fetchData(params);
    }
  }, [fetchData, params]); // Include params in the dependency array

  const refetch = useCallback(
    (...dynamicParams) => {
      // Optionally update params state if you want to persist the latest parameters
      setParams(dynamicParams);
      fetchData(dynamicParams);
    },
    [fetchData]
  );

  return { data, isLoading, error, refetch };
};

export default useFetch;
