import { fetchData } from '../../utils/apiUtils';
import { formatDateString } from '../../utils/generalUtils';
import { API_URL } from '../constants';

export const fetchPendingAvailabilities = async () => {
	try {
		const res = await fetchData(`${API_URL}/availability/pending`, 'GET');
		if (res.status === 204) {
			return [];
		} else if (res.status === 200) {
			const jsonRes = await res.json();
			return jsonRes.employees;
		} else {
			return [];
		}
	} catch (err) {
		console.log(err);
		return [];
	}
};

export const fetchPendingTimeOffs = async () => {
	try {
		const res = await fetchData(`${API_URL}/timeoffrequest/pending`, 'GET');
		if (res.status === 204) {
			return [];
		} else if (res.status === 200) {
			const jsonRes = await res.json();
			return jsonRes.timeoff;
		} else {
			return [];
		}
	} catch (err) {
		console.log(err);
		return [];
	}
};

export const fetchArchivedAvailabilities = async () => {
	try {
		const res = await fetchData(`${API_URL}/availability/archived`, 'GET');
		if (res.status === 204) {
			return [];
		} else if (res.status === 200) {
			const jsonRes = await res.json();
			return jsonRes.employees;
		} else {
			return [];
		}
	} catch (err) {
		console.log(err);
		return [];
	}
};

export const fetchArchivedTimeOffs = async () => {
	try {
		const res = await fetchData(`${API_URL}/timeoffrequest/archived`, 'GET');
		if (res.status === 204) {
			return [];
		} else if (res.status === 200) {
			const jsonRes = await res.json();
			return jsonRes.timeoff;
		} else {
			return [];
		}
	} catch (err) {
		console.log(err);
		return [];
	}
};

export const fetchIndividualAvailability = async user_id => {
	try {
		const res = await fetchData(`${API_URL}/availability/${user_id}`, 'GET');
		if (res.status === 200) {
			const jsonRes = await res.json();
			return jsonRes.availability;
		} else {
			return false;
		}
	} catch (err) {
		console.log(err);
		return false;
	}
};

export const putShifts = async body => {
	try {
		const res = await fetchData(`${API_URL}/shifts`, 'PUT', body);
		if (res.status === 200) {
			return true;
		} else {
			return false;
		}
	} catch (err) {
		console.log(err);
		return false;
	}
};

export const postIndividualAvailability = async (user_id, body) => {
	try {
		const res = await fetchData(
			`${API_URL}/availability/${user_id}`,
			'POST',
			body
		);
		if (res.ok) {
			return true;
		} else {
			return false;
		}
	} catch (err) {
		console.log(err);
		return false;
	}
};

export const putTimeOff = async (timeOffRequestId, accepted) => {
	try {
		const fetchUrl =
			`${API_URL}/timeoffrequest` +
			(accepted ? `/approve/` : `/reject/`) +
			timeOffRequestId;

		const res = await fetchData(fetchUrl, 'PUT');
		if (res.ok) {
			return true;
		} else {
			return false;
		}
	} catch (err) {
		console.log(err);
		return false;
	}
};

export const postAvailability = async (employeeId, accepted, body) => {
	try {
		const fetchUrl =
			`${API_URL}/availability` +
			(accepted ? `/approved/` : `/rejected/`) +
			employeeId;

		const res = await fetchData(fetchUrl, 'POST', body);
		if (res.ok) {
			return true;
		} else {
			return false;
		}
	} catch (err) {
		console.log(err);
		return false;
	}
};

export const putNoShow = async (employeeId, date) => {
	try {
		const res = await fetchData(
			`${API_URL}/shifts/noshow/` + employeeId + '?date=' + date,
			'PUT'
		);
		if (res.ok) {
			return true;
		} else {
			return false;
		}
	} catch (err) {
		console.log(err);
		return false;
	}
};

export const putShowedUp = async (employeeId, date) => {
	try {
		const res = await fetchData(
			`${API_URL}/shifts/showedup/` + employeeId + '?date=' + date,
			'PUT'
		);
		if (res.ok) {
			return true;
		} else {
			return false;
		}
	} catch (err) {
		console.log(err);
		return false;
	}
};

export const fetchShiftsDate = async (start, end) => {
	try {
		console.log(start);
		console.log(end);
		// console.log(start, end, formatDateString(new Date(start)), formatDateString(new Date(end)));
		const res = await fetchData(
			end === null
				? `${API_URL}/shifts?date=${formatDateString(new Date(start))}`
				: `${API_URL}/shifts?start=${formatDateString(
						new Date(start)
				  )}&end=${formatDateString(new Date(end))}`,
			'GET'
		);
		if (res.ok) {
			const jsonRes = await res.json();
			return jsonRes.shifts;
		} else {
			return [];
		}
	} catch (err) {
		console.log(err);
		return [];
	}
};

export const fetchShiftsFuture = async (box_id, date) => {
	try {
		const res = await fetchData(
			`${API_URL}/shifts/future/${box_id}?date=${date}`,
			'GET'
		);
		if (res.status === 200) {
			const jsonRes = await res.json();
			return jsonRes.shifts;
		} else {
			return [];
		}
	} catch (err) {
		console.log(err);
		return [];
	}
};

export const createTimeOffRequest = async body => {
	try {
		const res = await fetchData(`${API_URL}/timeoffrequest`, 'POST', body);
		if (res.ok) {
			return true;
		} else {
			return false;
		}
	} catch (err) {
		console.log(err);
		return false;
	}
};

export const createAvailabilityRequest = async (employeeId, body) => {
	try {
		const res = await fetchData(
			`${API_URL}/availability/request/${employeeId}`,
			'POST',
			body
		);
		if (res.ok) {
			return true;
		} else {
			return false;
		}
	} catch (err) {
		console.log(err);
		return false;
	}
};
