// import { Outlet } from 'react-router-dom';
// import { useState, useEffect } from 'react';
// import useRefreshToken from '@hooks/useRefreshToken/useRefreshToken';
// import useAuth from '@hooks/useAuth/useAuth';
// import LoadingPage from '@components/pages/LoadingPage';
// import FetchInterceptorProvider from '@context/FetchInterceptorsProvider/FetchInterceptorProvider';

// function PersistLogin() {
// 	const [isLoading, setIsLoading] = useState(true);
// 	const refresh = useRefreshToken();
// 	const { auth } = useAuth();

// 	useEffect(() => {
// 		let isMounted = true;

// 		const verifyRefreshToken = async () => {
// 			try {
// 				await refresh();
// 			} catch (err) {
// 				console.error(err);
// 			} finally {
// 				isMounted && setIsLoading(false);
// 			}
// 		};

// 		// persist added here AFTER tutorial video
// 		// Avoids unwanted call to verifyRefreshToken
// 		!auth?.token ? verifyRefreshToken() : setIsLoading(false);

// 		return () => (isMounted = false);
// 	}, []);

// 	useEffect(() => {}, [isLoading]);

// 	return (
// 		<>
// 			{isLoading ? (
// 				<LoadingPage />
// 			) : (
// 				<FetchInterceptorProvider>
// 					<Outlet />
// 				</FetchInterceptorProvider>
// 			)}
// 		</>
// 	);
// }

// export default PersistLogin;

import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import useRefreshToken from '@hooks/useRefreshToken/useRefreshToken';
import useAuth from '@hooks/useAuth/useAuth';
import LoadingPage from '@components/pages/LoadingPage';
import FetchInterceptorProvider from '@context/FetchInterceptorsProvider/FetchInterceptorProvider';

function PersistLogin() {
	const [isLoading, setIsLoading] = useState(true);
	const refresh = useRefreshToken();
	const { auth } = useAuth();

	useEffect(() => {
		let isMounted = true;

		const verifyRefreshToken = async () => {
			try {
				await refresh();
			} catch (err) {
				console.error(err);
			} finally {
				isMounted && setIsLoading(false);
			}
		};

		if (!auth?.token) {
			verifyRefreshToken();
		} else {
			setIsLoading(false);
		}

		return () => {
			isMounted = false;
		};
	}, []);

	return isLoading ? <LoadingPage /> : <Outlet />;
}

export default PersistLogin;
