import React, { useRef, useEffect } from 'react';
import { Animated, Easing, View, StyleSheet, Image } from 'react-native';
import Icon from '../../assets/gif/buoy_loading.webp'; // Replace with the actual path to your icon image

const SpinningIcon = ({ containerStyle, iconStyle }) => {
	const spinValue = useRef(new Animated.Value(0)).current;

	useEffect(() => {
		const spin = () => {
			spinValue.setValue(0);
			Animated.timing(spinValue, {
				toValue: 1,
				duration: 1500,
				easing: Easing.linear,
				useNativeDriver: true,
			}).start(() => spin());
		};
		spin();
	}, [spinValue]);

	// Instead of interpolating, directly use the spinValue to continuously rotate
	const spin = spinValue.interpolate({
		inputRange: [0, 1],
		outputRange: ['0deg', '360deg'],
	});

	return (
		<View style={[styles.container, containerStyle]}>
			<Animated.Image
				source={Icon}
				style={[styles.icon, { transform: [{ rotate: spin }] }, iconStyle]}
			/>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
	},
	icon: {
		width: 40,
		height: 40,
	},
});

export default SpinningIcon;
