import { isSameDay, isDateInRange } from './generalUtils';
import { View, Text } from 'react-native';

export const fetchEmployeeListDataForDay = (date, employees) => {
  // Find the employee assigned to this location for the current day
  const currDay = new Date(date);
  const employeesForDay = employees.filter(employee => {
    const hasShiftForDay = employee.shifts.some(shift => {
      const shiftDate = new Date(shift.date_assigned);
      shiftDate.setHours(shiftDate.getHours() + 5);
      return isSameDay(currDay, shiftDate) && !shift.no_show;
    });
    // Check if the employee has approved time off on the current day of the week
    const hasApprovedTimeOff = employee.approvedTimeOff.some(timeOff => {
      const startDate = new Date(timeOff.start_date);
      const endDate = new Date(timeOff.end_date);

      return isDateInRange(currDay, startDate, endDate);
    });
    return hasShiftForDay && !hasApprovedTimeOff;
  });
  return employeesForDay;
};

export const buildEmployeeLocationMap = (date, employees) => {
  const employeeLocationMap = {};
  employees.forEach(employee => {
    employee.shifts.forEach(shift => {
      const shiftDate = new Date(shift.date_assigned);
      shiftDate.setHours(shiftDate.getHours() + 5);

      if (isSameDay(date, shiftDate) && shift.box_id != null) {
        employeeLocationMap[shift.box_id] = employee;
      }
    });
  });
  return employeeLocationMap;
};

export const formatScheduleAlertMessage = location => {
  return (
    <View>
      <Text>
        This employee is already assigned to{' '}
        <Text style={{ fontWeight: 'bold' }}>{location}</Text>. Are you sure you
        want to change their location?
      </Text>
    </View>
  );
};
