import React, { useState, useEffect } from 'react';
import {
	View,
	Text,
	TouchableOpacity,
	StyleSheet,
	Animated,
} from 'react-native';
import { Feather } from '@expo/vector-icons';

function Banner({ type, message, see }) {
	const [progress, setProgress] = useState(new Animated.Value(0));
	const [visible, setVisible] = useState(see);
	const [slideAnimation] = useState(new Animated.Value(0));

	useEffect(() => {
		console.log('Here');
		if (visible) {
			Animated.timing(slideAnimation, {
				toValue: 1,
				duration: 500,
				useNativeDriver: true,
			}).start();
			Animated.timing(progress, {
				toValue: 1,
				duration: 5000, // 5 seconds
				useNativeDriver: false,
			}).start(() => {
				// Slide up animation
				Animated.timing(slideAnimation, {
					toValue: 0,
					duration: 500,
					useNativeDriver: true,
				}).start(() => {
					// Animation completed, hide the banner
					console.log('Timer working');
					setVisible(false);
				});
			});
		}
	}, [visible]);

	const handleClose = () => {
		setVisible(false);
		// Reset progress
		progress.setValue(0);
	};

	const getColor = () => {
		switch (type) {
			case 'success':
				return '#4CAF50'; // Green
			case 'error':
				return '#F44336'; // Red
			default:
				return '#2196F3'; // Default blue
		}
	};

	return (
		<Animated.View
			style={[
				styles.container,
				{
					backgroundColor: getColor(),
					transform: [
						{
							translateY: slideAnimation.interpolate({
								inputRange: [0, 1],
								outputRange: [-200, 0],
							}),
						},
					],
				},
			]}
		>
			<TouchableOpacity
				onPress={() => {
					handleClose();
				}}
				style={styles.closeButton}
			>
				<Feather name="x-circle" size={24} color="white" />
			</TouchableOpacity>
			<View
				style={{
					width: '100%',
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'center',
					alignContent: 'center',
					height: '100%',
				}}
			>
				<Text style={styles.message}>{message}</Text>
			</View>
			<Animated.View
				style={[
					styles.progressBar,
					{
						width: progress.interpolate({
							inputRange: [0, 1],
							outputRange: ['100%', '0%'],
						}),
					},
				]}
			/>
		</Animated.View>
	);
}

const styles = StyleSheet.create({
	container: {
		position: 'absolute',
		right: 50,
		top: 30,
		height: 75,
		width: 400,
		borderRadius: 8,
		paddingHorizontal: 20,
		paddingTop: 10,
		zIndex: 99999,
		// overflow: 'hidden',
	},
	closeButton: {
		position: 'absolute',
		top: 10,
		right: 10,
		zIndex: 10000,
	},
	closeButtonText: {
		fontSize: 10,
		color: 'white',
	},
	message: {
		fontSize: 18,
		fontWeight: 'bold',
		color: 'white',
		marginTop: 10,
	},
	progressBar: {
		position: 'absolute',
		bottom: 0,
		left: 0,
		backgroundColor: 'rgba(255, 255, 255, 0.5)',
		height: 5,
	},
});

export default Banner;
