import AsyncStorage from '@react-native-async-storage/async-storage';
import useAuth from './useAuth';
import { fetchLogout } from '../api/auth/auth';

const useLogout = () => {
	const { setAuth } = useAuth();

	const logout = async () => {
		setAuth({});
		await AsyncStorage.clear();
		try {
			await fetchLogout();
		} catch (err) {
			console.error(err);
		}
	};

	return logout;
};

export default useLogout;
