// packages/context/WebNavigationProvider.js
import React, { useCallback } from 'react';
import NavigationContext from '@context/NavigationContext/NavigationContext';
import { useNavigate } from 'react-router-dom';

export const WebNavigationProvider = ({ children }) => {
	const navigateFn = useNavigate();

	const navigate = useCallback(
		route => {
			navigateFn(route);
		},
		[navigateFn]
	);

	return (
		<NavigationContext.Provider value={{ navigate }}>
			{children}
		</NavigationContext.Provider>
	);
};
