// import { createContext, useContext, useState } from 'react';
// import { View, StyleSheet } from 'react-native';
// import Banner from '@components/molecules/Banner';

// const BannerContext = createContext();

// export const BannerProvider = ({ children }) => {
// 	const [bannerVisible, setBannerVisible] = useState(false);
// 	const [bannerType, setBannerType] = useState('');
// 	const [bannerMessage, setBannerMessage] = useState('');

// 	const showBanner = (type, message) => {
// 		setBannerType(type);
// 		setBannerMessage(message);
// 		setBannerVisible(true);

// 		setTimeout(() => {
// 			setBannerVisible(false);
// 		}, 5500);
// 	};

// 	return (
// 		<BannerContext.Provider value={{ showBanner }}>
// 			{children}
// 			{bannerVisible && (
// 				<View style={StyleSheet.absoluteFill}>
// 					<Banner type={bannerType} message={bannerMessage} see={bannerVisible} />
// 				</View>
// 			)}
// 		</BannerContext.Provider>
// 	);
// };

// export const useBanner = () => {
// 	return useContext(BannerContext);
// };

// components/BannerProvider.js

import React, { createContext, useContext, useState } from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import Banner from '@components/molecules/Banner';

const BannerContext = createContext();

export const BannerProvider = ({ children }) => {
	const [bannerVisible, setBannerVisible] = useState(false);
	const [bannerType, setBannerType] = useState('');
	const [bannerMessage, setBannerMessage] = useState('');

	const showBanner = (type, message) => {
		setBannerType(type);
		setBannerMessage(message);
		setBannerVisible(true);

		setTimeout(() => {
			setBannerVisible(false);
		}, 5500);
	};

	return (
		<BannerContext.Provider value={{ showBanner }}>
			{children}
			{bannerVisible && (
				<View
					style={
						Platform.OS === 'web'
							? styles.webBannerContainer
							: styles.nativeBannerContainer
					}
					pointerEvents={Platform.OS === 'web' ? 'box-none' : 'auto'}
				>
					<Banner
						type={bannerType}
						message={bannerMessage}
						see={bannerVisible}
						style={Platform.OS === 'web' ? styles.webBanner : styles.nativeBanner}
					/>
				</View>
			)}
		</BannerContext.Provider>
	);
};

export const useBanner = () => {
	return useContext(BannerContext);
};

const styles = StyleSheet.create({
	nativeBannerContainer: {
		...StyleSheet.absoluteFill,
		justifyContent: 'flex-start', // Adjust as needed
		alignItems: 'center',
		zIndex: 1000, // Ensure it's above other elements
	},
	webBannerContainer: {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		// Adjust the height based on your banner's height
		// Alternatively, use 'auto' if the banner height is dynamic
		// height: 60,
		justifyContent: 'center',
		alignItems: 'center',
		zIndex: 1000, // Ensure it's above other elements
		// Allow underlying elements to be clickable
		pointerEvents: 'box-none',
	},
	nativeBanner: {
		// Add any additional styles specific to native banners here
	},
	webBanner: {
		// Add any additional styles specific to web banners here
		// Ensure the banner itself can capture pointer events
		pointerEvents: 'auto',
	},
});
