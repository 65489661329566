export const fetchData = async (
	url,
	method = 'GET',
	body = null,
	includeCredentials = false,
	token = null
) => {
	const headers = {
		'Content-Type': 'application/json',
		Origin: 'https://www.jacobawebb.com',
		'Access-Control-Request-Method': method,
		'Access-Control-Request-Headers': 'Content-Type, Authorization',
		Authorization: token ? `Bearer ${token}` : '',
	};

	const options = {
		method,
		headers,
		body: body ? JSON.stringify(body) : null,
		credentials: includeCredentials ? 'include' : 'omit',
	};

	const response = await fetch(url, options);

	if (!response.ok) {
		throw new Error(`Error: ${response.status} - ${response.statusText}`);
	}

	return response;
};
