// FetchInterceptorProvider.js
import React, { useCallback } from 'react';
import useFetchInterceptors from '@hooks/useFetchInterceptors/useFetchInterceptors';
import { API_URL } from '../../api/constants';

const FetchInterceptorProvider = ({ children }) => {
	const filterFn = useCallback(url => {
		console.log(url);
		if (
			typeof url !== 'string' ||
			url.indexOf(API_URL) === -1 ||
			url.indexOf('refresh') !== -1 ||
			url.indexOf('logout') !== -1
		) {
			console.log("Caught a request that doesn't need an interceptor", url);
			return false;
		}
		return true;
	}, []);

	useFetchInterceptors(filterFn);

	return <>{children}</>;
};

export default FetchInterceptorProvider;
