import React, {
  useRef,
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect,
} from 'react';
import useFetch from '../hooks/useFetch';
import {
  fetchArchivedAvailabilities,
  fetchArchivedTimeOffs,
  fetchPendingAvailabilities,
  fetchPendingTimeOffs,
  postAvailability,
  postIndividualAvailability,
  putNoShow,
  putShifts,
  putShowedUp,
  putTimeOff,
  fetchShiftsDate,
} from '../api/scheduling/scheduling';
import { fetchAllInventoryLocations } from '../api/inventory/inventory';
import { fetchEmployeeListDataForDay } from '../utils/scheduleUtils';
import useMutation from '../hooks/useMutate';
import { formatDateDropdown } from '../utils/generalUtils';

const ScheduleContext = createContext();

export const useSchedule = () => useContext(ScheduleContext);

export const ScheduleProvider = ({ children }) => {
  const [employees, setEmployees] = useState([]);
  const [employeeToBeRemoved, setEmployeeToBeRemoved] = useState(null);
  const [employeeList, setEmployeeList] = useState([]);
  const [locationEmployeeMap, setLocationEmployeeMap] = useState({});
  const [currentLocationDate, setCurrentLocationDate] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [updateSchedule, setUpdateSchedule] = useState(true);
  const [viewingActive, setViewingActive] = useState(true);
  const [employeeHoveredIndex, setEmployeeHoveredIndex] = useState(null);
  const [employeePressedIndex, setEmployeePressedIndex] = useState(null);
  const [noEmployeesForWeek, setNoEmployeesForWeek] = useState(false);
  const [editMode, setEditMode] = useState(0);
  const [timeFrame, setTimeFrame] = useState(0);
  const [offset, setOffset] = useState(0);
  const [userHasSchedule, setUserHasSchedule] = useState(true);
  //fix this to be set to daySelected ?
  const [viewDate, setViewDate] = useState(new Date());
  const [isSelectingDate, setIsSelectingDate] = useState(false);
  const [currentEmployee, setCurrentEmployee] = useState(null);
  const [isBoxInfoVisible, setIsBoxInfoVisible] = useState(false);
  const [isChangeScheduleVisible, setIsChangeScheduleVisible] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [currTab, setCurrTab] = useState(0);
  const searchRef = useRef();
  const today = new Date();
  today.setHours(5, 0, 0, 0);

  const { data: employeesData, refetch: refetchEmployees } = useFetch(
    fetchShiftsDate,
    [],
    false
  );
  const { data: inventoryLocations, refetch: refetchInventoryLocations } =
    useFetch(fetchAllInventoryLocations, [], true);
  const { data: pendingAvailabilityList, refetch: refetchPendingAvailability } =
    useFetch(
      fetchPendingAvailabilities,
      [currTab, editMode, viewingActive],
      currTab === 1 && editMode === 1 && viewingActive === true
    );
  const {
    data: archivedAvailabilityList,
    refetch: refetchArchivedAvailability,
  } = useFetch(
    fetchArchivedAvailabilities,
    [currTab, editMode, viewingActive],
    currTab === 1 && editMode === 1 && viewingActive === false
  );
  const { data: pendingTimeOffList, refetch: refetchPendingTimeOff } = useFetch(
    fetchPendingTimeOffs,
    [currTab, editMode, viewingActive],
    currTab === 1 && editMode === 2 && viewingActive === true
  );
  const { data: archivedTimeOffList, refetch: refetchArchivedTimeOff } =
    useFetch(
      fetchArchivedTimeOffs,
      [currTab, editMode, viewingActive],
      currTab === 1 && editMode === 2 && viewingActive === false
    );

  const {
    mutate: callPutShifts,
    isLoading: isPuttingShifts,
    error: putShiftsError,
  } = useMutation(putShifts);
  const {
    mutate: callPostIndividualAvailability,
    isLoading: isPostingIndividualAvailability,
    error: postIndividualAvailabilityError,
  } = useMutation(postIndividualAvailability);
  const {
    mutate: callPostAvailability,
    isLoading: isPostingAvailability,
    error: postAvailabilityError,
  } = useMutation(postAvailability);
  const {
    mutate: callPutTimeOff,
    isLoading: isPuttingTimeOff,
    error: putTimeOffError,
  } = useMutation(putTimeOff);
  const {
    mutate: callPutNoShow,
    isLoading: isPuttingNoShow,
    error: putNoShowError,
  } = useMutation(putNoShow);
  const {
    mutate: callPutShowedUp,
    isLoading: isPuttingShowedUp,
    error: putShowedUpError,
  } = useMutation(putShowedUp);

  useEffect(() => {
    setEmployees(employeesData);
  }, [employeesData]);

  const changeAvailability = useCallback(async (user_id, body) => {
    try {
      const data = await callPostIndividualAvailability(user_id, body);
      if (data) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.error('Update failed:', err);
      return false;
    }
    //onTimeChange(timeFrame, offset, viewDate);
  }, []);

  const handleShiftRequest = useCallback(async (employeeId, accepted) => {
    try {
      const data = await callPostAvailability(employeeId, accepted, {
        employeeId,
      });
      if (data) {
        refetchPendingAvailability();
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.error('Update failed:', err);
      return false;
    }
  }, []);

  const changeTimeOff = useCallback(async (timeOffRequestId, accepted) => {
    try {
      const data = await callPutTimeOff(timeOffRequestId, accepted);
      if (data) {
        refetchPendingTimeOff();
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.error('Update failed:', err);
      return false;
    }
  }, []);

  const handleEmployeeNoShow = useCallback(async (employeeId, date) => {
    try {
      const data = await callPutNoShow(employeeId, formatDateDropdown(date));
      if (data) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.error('Update failed:', err);
      return false;
    }
  }, []);

  const handleEmployeeShowedUp = useCallback(async (employeeId, date) => {
    try {
      const data = await callPutShowedUp(employeeId, formatDateDropdown(date));
      if (data) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.error('Update failed:', err);
      return false;
    }
    //on true: onTimeChange(timeFrame, offset, date)
  }, []);

  const swapShifts = useCallback(async body => {
    try {
      const data = await callPutShifts(body);
      if (data) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.error('Update failed:', err);
      return false;
    }
  }, []);

  // Function to handle availability change
  const handleAvailabilityChange = useCallback(
    (employee_id, dayName, newValue, oldValues) => {
      // Implementation...
    },
    []
  );

  // Function to handle time off request
  const handleTimeOffRequest = useCallback((timeOffRequestId, accepted) => {
    // Implementation...
  }, []);

  // Function to handle employee press
  const handleEmployeePress = useCallback(employee => {
    // Implementation...
  }, []);

  const onTimeChange = useCallback(async (timeframe, offset, date) => {
    let start = new Date(today); // Clone the selected day to avoid mutating it
    let end = new Date(start); // Clone the start date
    if (timeframe === 0) {
      if (date != null && date != undefined) {
        start = new Date(date);
        end = null;
      }
    } else if (timeframe === 1) {
      start.setDate(start.getDate() - start.getDay());
      start.setDate(start.getDate() + 7 * offset);
      end.setDate(start.getDate() + 6);
    } else if (timeframe === 2) {
      end.setMonth(end.getMonth() + 1 + offset);
      end.setDate(0);
      start.setMonth(start.getMonth() + offset);
      start.setDate(1);
    }
    refetchEmployees(start, end);
  }, []);

  const onDayPress = useCallback(async date => {
    console.log(employees);
    setEmployeeList(fetchEmployeeListDataForDay(date, employees));
    setTimeFrame(0);
    onTimeChange(0, 0, date);
    setOffset(0);
    setViewDate(date);
    setIsSelectingDate(false);
  }, []);

  const value = {
    // states
    // employees,
    employeeToBeRemoved,
    employeeList,
    locationEmployeeMap,
    currentLocationDate,
    currentLocation,
    updateSchedule,
    viewingActive,
    employeeHoveredIndex,
    employeePressedIndex,
    noEmployeesForWeek,
    editMode,
    timeFrame,
    offset,
    viewDate,
    isSelectingDate,
    currentEmployee,
    isBoxInfoVisible,
    isChangeScheduleVisible,
    alertVisible,
    searchTerm,
    currTab,
    userHasSchedule,
    employees,

    // set states
    // setEmployees,
    setEmployeeToBeRemoved,
    setEmployeeList,
    setLocationEmployeeMap,
    setCurrentLocationDate,
    setCurrentLocation,
    setUpdateSchedule,
    setViewingActive,
    setEmployeeHoveredIndex,
    setEmployeePressedIndex,
    setNoEmployeesForWeek,
    setEditMode,
    setTimeFrame,
    setOffset,
    setViewDate,
    setIsSelectingDate,
    setCurrentEmployee,
    setIsBoxInfoVisible,
    setIsChangeScheduleVisible,
    setAlertVisible,
    setSearchTerm,
    setCurrTab,
    setUserHasSchedule,
    setEmployees,

    // refs
    searchRef,

    // variables
    today,

    // Important contextual functions
    handleAvailabilityChange,
    handleTimeOffRequest,
    handleEmployeePress,
    onDayPress,
    onTimeChange,

    // Data for scheduling needs
    inventoryLocations,
    pendingAvailabilityList,
    archivedAvailabilityList,
    pendingTimeOffList,
    archivedTimeOffList,
    employeesData,

    // Refetch data
    refetchEmployees,

    // Mutation functions (POST, PUT, DELETE needs are facilitated via these functions)
    swapShifts,
    handleEmployeeShowedUp,
    handleEmployeeNoShow,
    handleShiftRequest,
    changeAvailability,
    changeTimeOff,
  };

  return (
    <ScheduleContext.Provider value={value}>
      {children}
    </ScheduleContext.Provider>
  );
};
