// // import React, { useRef, useEffect } from 'react';
// // import { Animated, Easing, View, StyleSheet } from 'react-native';
// // import Icon from '../../assets/gif/buoy_loading.webp'; // Replace with the actual path to your icon image

// // const SpinningIcon = ({ containerStyle, iconStyle }) => {
// // 	const spinValue = useRef(new Animated.Value(0)).current;

// // 	useEffect(() => {
// // 		const spin = () => {
// // 			spinValue.setValue(0);
// // 			Animated.timing(spinValue, {
// // 				toValue: 1,
// // 				duration: 1500,
// // 				easing: Easing.linear,
// // 				useNativeDriver: true,
// // 			}).start(() => spin());
// // 		};
// // 		spin();
// // 	}, [spinValue]);

// // 	// Instead of interpolating, directly use the spinValue to continuously rotate
// // 	const spin = spinValue.interpolate({
// // 		inputRange: [0, 1],
// // 		outputRange: ['0deg', '360deg'],
// // 	});

// // 	return (
// // 		<View style={[styles.container, containerStyle]}>
// // 			<Animated.Image
// // 				source={Icon}
// // 				style={[styles.icon, { transform: [{ rotate: spin }] }, iconStyle]}
// // 			/>
// // 		</View>
// // 	);
// // };

// // const styles = StyleSheet.create({
// // 	container: {
// // 		flex: 1,
// // 		justifyContent: 'center',
// // 		alignItems: 'center',
// // 	},
// // 	icon: {
// // 		width: 40,
// // 		height: 40,
// // 	},
// // });

// // export default SpinningIcon;

// // SpinningIcon.js

// import React from 'react';
// import Icon from '../../assets/gif/buoy_loading.webp'; // Update the path as necessary

// const SpinningIcon = ({ containerStyle, iconStyle }) => {
// 	return (
// 		<div style={{ ...styles.container, ...containerStyle }}>
// 			<img
// 				src={Icon}
// 				style={{ ...styles.icon, ...iconStyle, ...styles.spinAnimation }}
// 				alt="Loading"
// 			/>
// 		</div>
// 	);
// };

// const styles = {
// 	container: {
// 		display: 'flex',
// 		justifyContent: 'center',
// 		alignItems: 'center',
// 	},
// 	icon: {
// 		width: '40px',
// 		height: '40px',
// 	},
// 	spinAnimation: {
// 		animation: 'spin 1.5s linear infinite',
// 	},
// 	// Add keyframes for the spin animation
// 	'@keyframes spin': {
// 		from: { transform: 'rotate(0deg)' },
// 		to: { transform: 'rotate(360deg)' },
// 	},
// };

// export default SpinningIcon;

// SpinningIcon.js

import React from 'react';
import './SpinningIcon.css'; // Import the CSS file
import Icon from '../../assets/gif/buoy_loading.webp'; // Update the path as necessary

const SpinningIcon = ({ containerStyle, iconStyle }) => {
	return (
		<div className="spinning-icon-container" style={containerStyle}>
			<img src={Icon} className="spinning-icon" style={iconStyle} alt="Loading" />
		</div>
	);
};

export default SpinningIcon;
