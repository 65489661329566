import { useState, useCallback } from 'react';

const useMutation = apiFunc => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const mutate = useCallback((...args) => {
    setIsLoading(true);
    setError(null);
    return apiFunc(...args)
      .then(result => {
        setData(result);
        setIsLoading(false);
        return result; // Resolve with result to allow .then() chaining
      })
      .catch(err => {
        setError(err);
        setIsLoading(false);
        throw err; // Reject with error to allow .catch() chaining
      });
  }, []);

  return { mutate, isLoading, error, data };
};

export default useMutation;
