import AsyncStorage from '@react-native-async-storage/async-storage';
import moment from 'moment';

// set data to storage
export async function setData(key, value) {
  try {
    await AsyncStorage.setItem(key, value);
  } catch (error) {
    console.log(error);
  }
}

// get data from storage
export async function getData(key, defaultValue = null) {
  try {
    const value = await AsyncStorage.getItem(key);

    if (value !== null) {
      return value;
    }
    return defaultValue;
  } catch (error) {
    console.log(error);
    return defaultValue;
  }
}

export const formatDateDropdown = dateString => {
  const date = new Date(dateString);
  return date.toISOString().split('T')[0];
};

export function getObjectIndex(obj, path) {
  const properties = path.split('.');
  return properties.reduce(
    (acc, prop) => (acc && acc[prop] !== 'undefined' ? acc[prop] : ''),
    obj
  );
}

export function isSameDay(date1, date2) {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}

export const isDateInRange = (dateToCheck, startDate, endDate) =>
  dateToCheck >= startDate && dateToCheck <= endDate;

export const pad = num => `00${num}`.slice(-2);
export const formatDateString = date =>
  `${date.getUTCFullYear()}-${pad(date.getUTCMonth() + 1)}-${pad(
    date.getUTCDate()
  )} ${pad(date.getUTCHours())}:${pad(date.getUTCMinutes())}:${pad(
    date.getUTCSeconds()
  )}`;

export const getInitials = name => {
  const parts = name.split(' ');
  const initials = parts.map(part => part.charAt(0)).join('');
  return initials;
};

// Define color scale for heatmap from light blue to navy
export const getHeatmap = (max, num) => {
  const intensity = Math.min(num, max) / max; // Calculate intensity from 0 to 1
  const blueValue = Math.round(140 + 60 * intensity); // Scale blue value from 80 to 255
  const opacity = 0.15 * intensity;
  return `rgba(0, 0, ${blueValue}, ${opacity})`;
};

export const calculateAverages = (startDate, data) => {
  const start_date = startDate;

  // Initialize counters for umbrellas, chairs, and combos
  let umbrellaCount = 0;
  let chairCount = 0;
  let comboCount = 0;

  let profit = 0;

  // Loop through each booking
  data.forEach(booking => {
    const bookingStartDate = moment(booking.start);
    const bookingEndDate = moment(booking.end);

    const saleDate = moment(booking.sale_date);

    let days;

    if (startDate - bookingStartDate >= 0) {
      // count every day, since the booking starts AFTER the date we are looking
      days = bookingEndDate.diff(startDate, 'days') + 1;
    } else {
      days = bookingEndDate.diff(bookingStartDate, 'days') + 1;
    }

    // Increment counters based on the products in the booking
    booking.bookingItems.forEach(item => {
      if (saleDate - startDate >= 0) {
        profit += item.quantity * item.product.price * days;
      }
      switch (item.product.name) {
        case 'umbrella':
          umbrellaCount += item.quantity * days;
          break;
        case 'chair':
          chairCount += item.quantity * days;
          break;
        case 'combo':
          comboCount += item.quantity * days;
          break;
      }
    });
  });

  // Calculate the average by dividing the total count by the number of days
  const totalDays = moment().diff(start_date, 'days') + 1;
  const avgUmbrellas = umbrellaCount / totalDays;
  const avgChairs = chairCount / totalDays;
  const avgCombos = comboCount / totalDays;
  const avgProfit = profit / totalDays;
  const revenue = profit;

  const avgItems = {
    avgUmbrellas,
    avgChairs,
    avgCombos,
    avgProfit,
    revenue,
  };

  return avgItems;
};

export const calculateCombos = inventory => {
  const umbrellas = inventory.umbrellas;
  const chairs = inventory.chairs;

  const combos = Math.min(Math.floor(umbrellas / 1), Math.floor(chairs / 2));
  return combos;
};

export const filterDataDate = (startDate, data) => {
  let currentDate = new Date();
  let totalSales = 0;
  data.bookings.forEach(booking => {
    const saleDate = new Date(booking.sale_date);
    if (saleDate >= startDate && saleDate <= currentDate) {
      const bookingStartDate = new Date(booking.start_date);
      const bookingEndDate = new Date(booking.end_date);
      const bookingDays = Math.ceil(
        (bookingEndDate - bookingStartDate) / (1000 * 60 * 60 * 24)
      ); // Calculate the duration of the booking in days
      const bookingTotalSales = booking.booking_items.reduce((acc, item) => {
        // Assuming each item has a "quantity" and a "product_id", and there's a global product price lookup
        const productPrice = item.product.price;
        return acc + productPrice * item.quantity;
      }, 0);
      totalSales += bookingTotalSales * bookingDays;
    }
  });
  return totalSales;
};

export const filterPieData = (startDate, data) => {
  let currentDate = new Date();
  const paymentCounts = data.bookings.reduce((counts, booking) => {
    const saleDate = new Date(booking.sale_date);
    if (saleDate >= startDate && saleDate <= currentDate) {
      const paymentType = booking.payment_type;
      counts[paymentType] = (counts[paymentType] || 0) + 1;
    }
    return counts;
  }, {});

  // Calculate the total number of bookings within the specified date range
  const totalBookings = Object.values(paymentCounts).reduce(
    (total, count) => total + count,
    0
  );

  // Calculate the percentage of each payment type
  const paymentPercentages = {
    card: ((paymentCounts.card || 0) / totalBookings) * 100,
    cash: ((paymentCounts.cash || 0) / totalBookings) * 100,
    website: ((paymentCounts.website || 0) / totalBookings) * 100,
  };

  // console.log(paymentPercentages);
  const pie_data = [
    { value: paymentPercentages.website || 0, color: 'blue' },
    { value: paymentPercentages.card || 0, color: 'green' },
    { value: paymentPercentages.cash || 0, color: 'orange' },
  ];
  let flag = 0;
  pie_data.forEach(d => {
    if (d.value === 100) {
      flag = 1;
    }
  });
  if (flag === 1) {
    pie_data.forEach(d => {
      if (d.value === 100) {
        d.value = 99.999;
      } else {
        d.value = 0.0005;
      }
    });
  }
  return pie_data;
};
