import { useRef } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import useAuth from '@hooks/useAuth/useAuth';
import useLogout from '@hooks/useLogout/useLogout';
import { fetchRefresh } from '@api/auth/auth';

const useRefreshToken = () => {
	const { auth, setAuth } = useAuth();
	const isRefreshingRef = useRef(false);
	const refreshQueueRef = useRef([]);
	const logout = useLogout();

	const refresh = async () => {
		try {
			// If token refresh is already in progress, wait for it to complete
			if (isRefreshingRef.current) {
				// Create a promise that resolves when token refresh completes
				const refreshPromise = new Promise(resolve => {
					refreshQueueRef.current.push(resolve);
				});
				await refreshPromise;
				// Return the refreshed token
				return auth.token;
			}

			// Set the flag to indicate token refresh is in progress
			isRefreshingRef.current = true;
			const response = await fetchRefresh();

			if (!response) {
				throw new Error('Failed to refresh token');
			}

			// Retrieve user data from AsyncStorage
			const name = (await AsyncStorage.getItem('name')) || '';
			const role = (await AsyncStorage.getItem('role')) || '';
			const email = (await AsyncStorage.getItem('email')) || '';
			const user_id = (await AsyncStorage.getItem('user_id')) || '';

			setAuth(prev => ({
				...prev,
				token: response,
				name,
				role,
				email,
				id: user_id,
			}));

			// Reset the flag since token refresh is completed
			isRefreshingRef.current = false;

			// Resolve all pending fetch requests waiting for token refresh
			refreshQueueRef.current.forEach(resolve => resolve());
			refreshQueueRef.current = [];

			return response;
		} catch (error) {
			console.error('Error refreshing token:', error);
			isRefreshingRef.current = false;
			await logout();
			throw error;
		}
	};

	return refresh;
};

export default useRefreshToken;
