import { useLayoutEffect } from 'react';
import useAuth from '@hooks/useAuth/useAuth';
import useRefreshToken from '@hooks/useRefreshToken/useRefreshToken';

const useFetchInterceptors = filterFn => {
	const refresh = useRefreshToken();
	const { auth } = useAuth();

	useLayoutEffect(() => {
		const originalFetch = window.fetch;

		const fetchWithInterceptors = async (url, options, retryCount = 0) => {
			try {
				if (!filterFn || filterFn(url, options)) {
					const modifiedOptions = { ...options };

					if (!modifiedOptions.headers || !modifiedOptions.headers.Authorization) {
						if (auth?.token) {
							modifiedOptions.headers = {
								...modifiedOptions.headers,
								Authorization: `Bearer ${auth.token}`,
							};
						}
					}

					let response;
					if (retryCount < 2) {
						response = await originalFetch(url, modifiedOptions);
					}
					if (!response) {
						// Prevent unresolved promises
						return Promise.reject('No response received');
					}

					if (response.status === 403 && retryCount < 2) {
						const prevRequest = modifiedOptions;
						if (!prevRequest.sent) {
							prevRequest.sent = true;

							const newAccessToken = await refresh();

							prevRequest.headers = {
								...prevRequest.headers,
								Authorization: `Bearer ${newAccessToken}`,
							};

							return fetchWithInterceptors(url, prevRequest, retryCount + 1);
						}
					}

					return response;
				}
				return originalFetch(url, options);
			} catch (error) {
				return Promise.reject(error);
			}
		};

		// Override the global fetch function with the one that includes interceptors
		window.fetch = fetchWithInterceptors;

		// Clean up when the component unmounts
		return () => {
			window.fetch = originalFetch;
		};
	}, [auth, refresh, filterFn]);
};

export default useFetchInterceptors;

// // useFetchInterceptors.js
// import { useEffect, useRef } from 'react';
// import useAuth from '@hooks/useAuth/useAuth';
// import useRefreshToken from '@hooks/useRefreshToken/useRefreshToken';

// const useFetchInterceptors = filterFn => {
// 	const refresh = useRefreshToken();
// 	const { auth } = useAuth();

// 	// Create a ref to store the latest auth object
// 	const authRef = useRef(auth);

// 	console.log(authRef.current);
// 	// Update the ref whenever auth changes
// 	useEffect(() => {
// 		authRef.current = auth;
// 	}, [auth]);

// 	useEffect(() => {
// 		const originalFetch = window.fetch;

// 		const fetchWithInterceptors = async (url, options, retryCount = 0) => {
// 			try {
// 				console.log('1');
// 				if (!filterFn || filterFn(url, options)) {
// 					console.log('2');
// 					const modifiedOptions = { ...options };

// 					// Use the auth token from the ref
// 					const currentAuth = authRef.current;
// 					console.log(currentAuth);

// 					if (!modifiedOptions.headers || !modifiedOptions.headers.Authorization) {
// 						if (currentAuth?.token) {
// 							console.log('HERE?');
// 							modifiedOptions.headers = {
// 								...modifiedOptions.headers,
// 								Authorization: `Bearer ${currentAuth.token}`,
// 							};
// 						}
// 					}

// 					let response = await originalFetch(url, modifiedOptions);

// 					if (response.status === 401 && retryCount < 1) {
// 						// Refresh the token and retry the request
// 						await refresh();
// 						return fetchWithInterceptors(url, options, retryCount + 1);
// 					}

// 					return response;
// 				}
// 				return originalFetch(url, options);
// 			} catch (error) {
// 				return Promise.reject(error);
// 			}
// 		};

// 		// Override the global fetch function
// 		window.fetch = fetchWithInterceptors;

// 		// Cleanup
// 		return () => {
// 			window.fetch = originalFetch;
// 		};
// 	}, [refresh, filterFn]);

// 	// No need to include auth in dependencies due to useRef
// };

// export default useFetchInterceptors;
