// LoadingPage.js

import React from 'react';
import SpinningIcon from '../atoms/SpinningIcon';

const LoadingPage = () => {
	return (
		<div style={styles.container}>
			<SpinningIcon
				containerStyle={styles.loadingIconContainerStyle}
				iconStyle={styles.loadingIconStyle}
			/>
		</div>
	);
};

const styles = {
	container: {
		display: 'flex',
		height: '100%',
		width: '100%',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
	},
	loadingIconContainerStyle: {
		marginTop: '50px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	loadingIconStyle: {
		width: '90px',
		height: '90px',
	},
};

export default LoadingPage;
