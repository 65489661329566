import { useEffect } from 'react';
import useAuth from './useAuth';
import useRefreshToken from './useRefreshToken';

const useFetchInterceptors = filterFn => {
  const refresh = useRefreshToken();
  const { auth } = useAuth();

  useEffect(() => {
    const originalFetch = window.fetch;

    const fetchWithInterceptors = async (url, options) => {
      try {
        // Apply the interceptor logic only if the filter function returns true
        if (!filterFn || filterFn(url, options)) {
          // Modify request headers to include access token
          const modifiedOptions = { ...options };

          // Modify request headers to include access token if not already present
          if (
            !modifiedOptions.headers ||
            !modifiedOptions.headers.Authorization
          ) {
            modifiedOptions.headers = {
              ...modifiedOptions.headers,
              Authorization: `Bearer ${auth?.token}`,
            };
          }

          // Perform the fetch request
          const response = await originalFetch(url, modifiedOptions);

          // Check if response status is 403 (Forbidden)
          if (response.status === 403) {
            // Retrieve the original request from the error object
            const prevRequest = modifiedOptions;
            // Prevent infinite loop by checking if the request has already been sent
            if (!prevRequest.sent) {
              // Set a flag to indicate the request has been sent
              prevRequest.sent = true;
              // Attempt to refresh the access token
              const newAccessToken = await refresh();
              // Update request headers with new access token
              prevRequest.headers = {
                ...prevRequest.headers,
                Authorization: `Bearer ${newAccessToken}`,
              };

              // Retry the original request with the new access token
              return fetchWithInterceptors(url, prevRequest);
            }
          }

          return response;
        }
        // If the filter function returns false, bypass the interceptor and perform the original fetch
        return originalFetch(url, options);
      } catch (error) {
        return Promise.reject(error);
      }
    };

    // Override the global fetch function with the one that includes interceptors
    window.fetch = fetchWithInterceptors;

    // Clean up when the component unmounts
    return () => {
      window.fetch = originalFetch;
    };
  }, [auth, refresh, filterFn]);
};

export default useFetchInterceptors;
